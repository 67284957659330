import React, { useEffect, useState } from 'react'
import logo from '../../Assets/Logo-pagina.png'
import shortLogo from '../../Assets/Logo-arriba.png'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Navbar() {

  const [nav, setNav] = useState(true);
  const [home, setHome] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = width <= 768;

  const handleNavbar = () => {
    setNav(!nav);
  }


  useEffect(() => {
    if (location.pathname == '/') {
      setHome(true)
    } else {
      setHome(false)
    }
  }, [location.pathname])



  return (
    <div className={`fixed w-full top-0 flex justify-between items-center h-24 max-w-[100%] mx-auto p-4 text-white  bg-[#091615] md:border-b  border-white zindexnavbar`} role="navigation">
      <img src={isMobile ? shortLogo : logo} alt="Logo" className="h-13" />
      <ul className='hidden md:flex justify-between'>
        <li className='p-4'>
          <NavLink to="/" exact>INICIO</NavLink>
        </li>
        <li className='p-4'>
          <NavLink to="/nosotros" exact>NOSOTROS</NavLink>
        </li>
        <li className='p-4 '>
          <NavLink to="/servicios" exact>SERVICIOS</NavLink>
        </li>
        <li className='p-4'>
          <NavLink to="/clientes" exact>CLIENTES</NavLink>
        </li>
        <li className='p-4 '>
          <NavLink to="/contacto" exact>CONTACTO</NavLink>
        </li>
      </ul>
      <div onClick={handleNavbar} className='block md:hidden z-50'>
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu fill='white' size={20} />}
      </div>
      <div className={!nav ? 'fixed left-0 top-0 w-[100%] h-full  border-r-gray-900 bg-[#091615] ease-in-out duration-500 ' : 'fixed left-[-100%]'}>
        <img src={shortLogo} alt="Logo" className="h-13 m-4" />
        <div className='flex flex-col justify-between h-[70%]'>
          <ul className='uppercase p-4 flex flex-col gap-6 font-extrabold tracking-widest	'>
            <li className=' text-3xl'>
              <NavLink to="/" onClick={handleNavbar} exact>INICIO</NavLink>
            </li>
            <li className='text-3xl '>
              <NavLink to="/nosotros" onClick={handleNavbar} exact>NOSOTROS</NavLink>
            </li>
            <li className='text-3xl '>
              <NavLink to="/servicios" onClick={handleNavbar} exact>SERVICIOS</NavLink>
            </li>
            <li className=' text-3xl '>
              <NavLink to="/clientes" onClick={handleNavbar} exact>CLIENTES</NavLink>
            </li>
            <li className=' text-3xl '>
              <NavLink to="/contacto" onClick={handleNavbar} exact>CONTACTO</NavLink>
            </li>
          </ul>
          {/* <ul className='uppercase p-4 flex flex-col gap-3 font-light text-sm '>
            <li>INSTAGRAM</li>
            <li>FACEBOOK</li>
            <li>LINKEDIN</li>
          </ul> */}
          <div>
            <p className='p-4 font-[100] text-sm text-gray-400'>2023 Simple Soluciones</p>
            <NavLink to='/politicaseguridad' onClick={handleNavbar} className='p-4 text-sm font-[100] text-gray-400' >
              Politicas de Seguridad
            </NavLink>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Navbar