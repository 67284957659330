import React, { useEffect } from 'react'
import { FaDownload } from 'react-icons/fa'

function PoliticaSeguridad() {



    return (
        <div className='w-full'>
            <div className='max-w-[1000px] 2xl:max-w-[1200px] w-full flex flex-col gap-5 justify-center items-center h-screen py-36  text-white relative z-20 mx-auto p-5 md:px-0'>
                <div className='w-full flex flex-col md:flex-row justify-center items-center'>
                    <a href='https://s3.sa-east-1.amazonaws.com/imagenes.simple/Simplesoluciones/Politica+de+Ciber+Seguridad+de+Simple+Soluciones.docx' download className='border flex items-center justify-center gap-2 p-5 px-10 rounded-2xl' >
                        <FaDownload className='text-4xl' />
                        Descargar Politica de Seguridad
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PoliticaSeguridad