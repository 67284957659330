import React from 'react'
import Hero from '../../Components/Utils/Hero'
import DotPages from '../../Components/Utils/DotPages'
import { AiOutlineRightCircle } from 'react-icons/ai'
import CardServicios from '../../Components/Utils/CardServicios'
import Player from 'react-player'
import ReactPlayer from 'react-player'

function ServiciosScreen() {
  return (
    <div className='snap-y snap-mandatory relative w-full h-screen overflow-y-auto overflow-x-hidden scroll-smooth'>
      <div className='snap-start h-screen'>
        <Hero opacity={'opacity-[0.8]'} primerParrafo={'SOLUCIONES QUE'} segundoParrafo={'SUPERAN EXPECTATIVAS'} dotsNumber={2} activeDot={0} bgImage={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Simplesoluciones/mixkit-business-people-at-work-meeting-4809.mp4'} />
      </div>
      <div className='snap-start'>
        <div className='w-full relative noise'>
          <div className='max-w-[1000px] 2xl:max-w-[1500px] w-full flex flex-col gap-5 justify-center 2xl:h-screen h-min py-36  text-white relative z-20 mx-auto p-5 md:px-0 '>
            <div className='flex flex-col md:justify-start md:items-start w-full'>
              <h2 className='2xl:text-7xl md:text-5xl text-4xl text-center md:text-left mb-5'>SERVICIOS</h2>
              <div className='w-full border-t-2'></div>
              <p className='text-sm md:text-lg py-10 text-right md:text-left w-[90%] self-end md:self-start'>Somos una empresa comprometida en brindar servicios de alta calidad y eficiencia a nuestros clientes. Nuestra misión es simplificar la vida de las personas y hacer que sus experiencias sean más placenteras y gratificantes. Nos enorgullece contar con un equipo de profesionales altamente capacitados y comprometidos con la satisfacción de nuestros clientes. Ya sea que necesites asesoramiento, soporte técnico o cualquier tipo de servicio personalizado, estamos aquí para ayudarte en cada paso del camino. Confía en nosotros para superar tus expectativas y hacer que tu vida sea más fácil.</p>
              <div className='w-full border-t-2'></div>
              <div className='grid w-full grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-5 mt-5'>
                <CardServicios titulo={'TRANSFORMACIÓN DIGITAL'} list1={'Desarrollo de soluciones'} list2={'Estrategia CX - UX'} list3={'Estudios de voz de clientes'} list4={'Plan de trabajo de transformación'} descriptionDetail={'En nuestra empresa, lideramos la transformación digital, guiando a organizaciones hacia un futuro tecnológico próspero. Nos especializamos en la implementación de soluciones digitales de vanguardia que no solo optimizan las operaciones y procesos internos, sino que también mejoran de manera significativa la experiencia del cliente. Nuestro enfoque se centra en fomentar la innovación continua y en transformar los negocios para que puedan competir eficazmente en el acelerado mundo digital de hoy. Mediante estrategias personalizadas, elevamos la eficiencia, aumentamos la rentabilidad y aseguramos el crecimiento sostenible de nuestros clientes en el mercado digital.'} />
                <CardServicios titulo={'ACTIVIDAD COMERCIAL DIGITAL'} list1={'Leads Generation'} list2={'Performance Marketing'} list3={'Estrategias SEO'} descriptionDetail={'Simple Soluciones es una agencia digital que ofrece un servicio completo de manejo de redes sociales. Trabajamos con nuestros clientes para crear una estrategia de redes sociales personalizada, basada en el análisis del mercado y la audiencia.Desde allí, nos encargamos de todo el contenido en las redes sociales, incluyendo la creación de publicaciones, la programación y la publicación en las redes sociales relevantes para el cliente.Además de la creación de contenido, también nos encargamos de interactuar con los seguidores y responder a sus preguntas y comentarios en nombre del cliente.'} />
                <CardServicios titulo={'MARCA Y COMUNICACIONES'} list1={'Plan de desarrollo de TOM'} list2={'Desarrollo de estrategia Creativa'} list3={'Creación de piezas graficas'} descriptionDetail={'Nuestra firma se especializa en estrategias de marca y comunicaciones, creando identidades poderosas y mensajes resonantes que destacan en el mercado. A través de un profundo análisis del entorno y la audiencia, diseñamos estrategias que no solo definen la esencia única de cada marca, sino que también establecen conexiones emocionales fuertes con el público. Impulsamos la visibilidad y el reconocimiento de marca mediante campañas innovadoras y contenido de alto impacto, optimizando cada punto de contacto para fortalecer la lealtad del cliente y fomentar el crecimiento empresarial sostenido en un entorno competitivo.'} />
                <CardServicios titulo={'DESARROLLOS Y REPORTERIA'} list1={'Mapa de Calor'} list2={'Data Estudios'} list3={'Desarrollos web'} list4={'Desarrollos de APP'} descriptionDetail={'Simple Soluciones es una agencia digital especializada en ayudar a las empresas a desarrollar y ejecutar planes de comercialización digital efectivos. Nuestro enfoque comienza con una investigación detallada del mercado y del público objetivo de cada cliente, lo que nos permite entender sus necesidades y deseos. A partir de esta información, creamos un plan de acción personalizado que incluye estrategias de SEO, publicidad en línea, redes sociales, email marketing y mucho más.Nuestro objetivo es maximizar el éxito de nuestros clientes, por lo que trabajamos con ellos para implementar estas estrategias de manera efectiva, utilizando herramientas y técnicas avanzadas de análisis de datos para monitorear y ajustar su progreso.'} />
              </div>
            </div>
          </div>
          <div className='bg-[#409891] 2xl:w-[1200px] w-[400px] h-[400px] md:h-[400px] 2xl:h-[500px] rounded-full blur-[200px] absolute bottom-0 top-80 md:right-44 '></div>
        </div>
      </div>
      <div className='snap-start'>
        <div className='w-full relative noise'>
          <div className='max-w-[1000px] 2xl:max-w-[1500px] w-full flex flex-col gap-5 justify-center 2xl:h-screen h-min py-36  text-white relative z-20 mx-auto p-5 md:px-0 '>
            <h2 className='2xl:text-7xl md:text-5xl text-4xl self-start mb-5 text-white'>DESARROLLO APP</h2>
            <div className='w-full border-t-2'></div>
            <ReactPlayer url={'https://www.youtube.com/watch?v=LjQy1Gk4qqA'} width={'100%'} height={'90%'} controls>
            </ReactPlayer>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ServiciosScreen  