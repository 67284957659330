import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeScreen from './Screens/HomeScreen'
import Navbar from './Components/Section/Navbar'
import NosotrosScreen from './Screens/NosotrosScreen'
import ServiciosScreen from './Screens/ServiciosScreen'
import ClientesScreen from './Screens/ClientesScreen'
import ContactoScreen from './Screens/ContactoScreen'
import ServicioDetail from './Screens/ServicioDetail'
import Modal from './Components/Utils/Modal'
import PoliticaSeguridad from './Screens/Politicaseguridad'

const App = () => {
  return (
    <BrowserRouter >
      <Navbar />

      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/nosotros" element={<NosotrosScreen />} />
        <Route path="/servicios" element={<ServiciosScreen />} />
        <Route path="/clientes" element={<ClientesScreen />} />
        <Route path="/contacto" element={<ContactoScreen />} />
        <Route path='/politicaseguridad' element={<PoliticaSeguridad />} />
        <Route path="*" element={<HomeScreen />} />
        <Route path="/servicios-details/*" element={<ServicioDetail />} />
      </Routes>
    </ BrowserRouter>
  )
}

export default App